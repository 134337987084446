import { Profile2User, Book, Driving, Profile, UserSearch, People, Call, DocumentText, Ticket } from 'iconsax-react';
import { NavItemType } from 'types/menu';

const icons = {
  users: People,
  clients: Profile2User,
  contacts: Call,
  contracts: DocumentText,
  leads: Book,
  routes: Driving,
  consultant: Profile,
  analyst: UserSearch,
  tickets: Ticket
};

// ==============================|| MENU ITEMS - REGISTRATIONS ||============================== //

const registrations: NavItemType = {
  id: 'registrations',
  title: 'Cadastros',
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Usuários',
      type: 'item',
      disabled: false,
      url: '/registers/users',
      icon: icons.users
    },
    {
      id: 'clients',
      title: 'Clientes',
      type: 'item',
      disabled: false,
      url: '/registers/clients',
      icon: icons.clients
    },
    {
      id: 'contacts',
      title: 'Agendamentos',
      type: 'item',
      disabled: false,
      url: '/registers/contacts',
      icon: icons.contacts
    },
    {
      id: 'contracts',
      title: 'Contratos',
      type: 'item',
      disabled: false,
      url: '/registers/contracts',
      icon: icons.contracts
    },
    {
      id: 'leads',
      title: 'Leads',
      type: 'item',
      disabled: false,
      url: '/registers/leads',
      icon: icons.leads
    },
    {
      id: 'routes',
      title: 'Rotas',
      type: 'item',
      disabled: false,
      url: '/registers/routes',
      icon: icons.routes
    },
    {
      id: 'tickets',
      title: 'Chamados',
      type: 'item',
      disabled: false,
      icon: icons.tickets,
      url: '/registers/tickets'
    }
  ]
};

export default registrations;
