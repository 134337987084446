import createApiInstance from 'api/apiInstance';

const getUserMe = async () => {
  try {
    const apiInstance = await createApiInstance();
    return await apiInstance.get('/users/me');
  } catch (error) {
    console.error('Fail getUserMe: ', error);
    throw error;
  }
};

export default getUserMe;
