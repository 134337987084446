import createApiInstance from 'api/apiInstance';

const getUserSectorNameById = async (id: number) => {
  try {
    const apiInstance = await createApiInstance();
    const userData = await apiInstance.get(`/users/${id}`);
    const sectorId = userData.data.sectorId;
    if (!sectorId) return '';
    const sector = await apiInstance.get(`/sectors/${sectorId}`);
    return sector.data.name;
  } catch (error) {
    console.error('Fail getUserSectorNameById: ', error);
    return '';
  }
};

export default getUserSectorNameById;
