import { MoneyRecive, MoneySend } from 'iconsax-react';
import { NavItemType } from 'types/menu';

const icons = {
  billings: MoneySend,
  credits: MoneyRecive
};

// ==============================|| MENU ITEMS - FINANCIAL ||============================== //

const financial: NavItemType = {
  id: 'financial',
  title: 'Financeiro',
  type: 'group',
  children: [
    {
      id: 'billings',
      title: 'Cobranças',
      type: 'item',
      url: '/financial/billings',
      icon: icons.billings
    },
  ]
};

export default financial;
