import { Buildings2, Medal, Setting2, UserRemove } from 'iconsax-react';
import { NavItemType } from 'types/menu';

const icons = {
  configuration: Setting2,
  enterprises: Buildings2,
  denyClients: UserRemove,
  sectors: Medal
};

// ==============================|| MENU ITEMS - ADMINISTRATOR ||============================== //

const administrator: NavItemType = {
  id: 'administrator',
  title: 'Administrador',
  type: 'group',
  children: [
    {
      id: 'configuration',
      title: 'Configurações',
      type: 'item',
      disabled: false,
      url: '/administrator/configuration',
      icon: icons.configuration
    },
    {
      id: 'enterprises',
      title: 'Empresas',
      type: 'item',
      disabled: false,
      url: '/administrator/enterprises',
      icon: icons.enterprises
    },
    {
      id: 'client-blocked',
      title: 'Clientes Bloqueados',
      type: 'item',
      disabled: false,
      url: '/administrator/denylist',
      icon: icons.denyClients
    },
    {
      id: 'user-sector',
      title: 'Setores',
      type: 'item',
      disabled: false,
      url: '/administrator/sectors',
      icon: icons.sectors
    }
  ]
};

export default administrator;
