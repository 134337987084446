import { useMemo } from 'react';
import { Box, Grid, Divider, Drawer, Stack, Typography } from '@mui/material';
import ThemeMode from './ThemeMode';
import ThemeMenuLayout from './ThemeMenuLayout';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
import useConfig from 'hooks/useConfig';
import { Add } from 'iconsax-react';
import { HEADER_HEIGHT } from 'config';

// ==============================|| HEADER CONTENT - CUSTOMIZATION ||============================== //

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const Customization = ({ isOpen, handleClose }: Props) => {
  const { mode, menuOrientation } = useConfig();

  const themeMenuLayout = useMemo(() => <ThemeMenuLayout />, [menuOrientation]);
  const themeMode = useMemo(() => <ThemeMode />, [mode]);
  // const themeContrastView = useMemo(() => <ThemeContrast />, [themeContrast]);
  // const menuCaptionView = useMemo(() => <MenuCaption />, [menuCaption]);
  // const themeColor = useMemo(() => <ColorScheme />, [presetColor]);
  // const themeWidth = useMemo(() => <ThemeWidth />, [container]);
  // const themeFont = useMemo(() => <ThemeFont />, [fontFamily]);

  return (
    <>
      <Drawer
        sx={{
          zIndex: 2001
        }}
        anchor="right"
        onClose={handleClose}
        open={isOpen}
        PaperProps={{
          sx: {
            width: 350
          }
        }}
      >
        {isOpen && (
          <MainCard content={false} border={false}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5} sx={{ p: 2.5 }}>
              <Typography variant="h5">Configurações</Typography>
              <IconButton color="error" sx={{ p: 0 }} onClick={handleClose}>
                <Add size={28} style={{ transform: 'rotate(45deg)' }} />
              </IconButton>
            </Stack>
            <SimpleBar
              sx={{
                '& .simplebar-content': {
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
            >
              <Box sx={{ p: 3, height: `calc(100vh - ${HEADER_HEIGHT}px)` }}>
                <Grid container spacing={2.5}>
                  {/* theme-mode */}
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="textPrimary">
                        Tema
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Escolha modo escuro ou claro
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {themeMode}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {/* theme-contrast */}
                  {/* <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="textPrimary">
                        Contraste
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Escolha o tema de contraste / sombra
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {themeContrastView}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid> */}

                  {/* custom-theme */}
                  {/* <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="textPrimary">
                        Customizar tema
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Escolha a cor principal do tema
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {themeColor}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid> */}

                  {/* menu-caption */}
                  {/* <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="textPrimary">
                        Legenda da barra lateral
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Oculta a legenda da barra lateral
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {menuCaptionView}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid> */}

                  {/* theme-orientation */}
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="textPrimary">
                        Orientação do menu
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Escolha a orientação do menu vertical ou horizontal
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {themeMenuLayout}
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Divider /> */}
                  </Grid>

                  {/* theme-container */}
                  {/* <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="textPrimary">
                        Largura do layout
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Escolha a forma de layout das caixas
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {themeWidth}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid> */}

                  {/* theme-font-family */}
                  {/* <Grid item xs={12}>
                    <Stack>
                      <Typography variant="subtitle1" color="textPrimary">
                        Font Family
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Choose your font family.
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {themeFont}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid> */}
                </Grid>
              </Box>
            </SimpleBar>
          </MainCard>
        )}
      </Drawer>
    </>
  );
};

export default Customization;
