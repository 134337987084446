import axios from 'axios';

const getToken = async () => {
  try {
    const token = localStorage.getItem('serviceToken');
    return token;
  } catch (err) {
    console.log('Erro ao carregar token: ', err);
  }
};

const createApiInstance = async () => {
  const token = await getToken();

  const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (token) {
    apiInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return apiInstance;
};

export default createApiInstance;
