import { createContext, useEffect, useReducer, ReactElement } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, UPDATE_PROFILE, SET_ACTIVE_ENTERPRISES } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { KeyedObject } from 'types/root';
import { AuthProps, JWTContextType } from 'types/auth';
import loginService from 'services/auth/loginService';
import getUserMe from 'services/user/getUserMe';
import { User } from 'types/services';

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  activeEnterprise: 'all'
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const getUserData = await getUserMe();
          const user = getUserData.data;

          const activeEnterprises = localStorage.getItem('activeEnterprises');
          if (activeEnterprises) {
            setActiveEnterprise(Number(activeEnterprises));
          }

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, password: string) => {
    const authResponse = await loginService(email, password);

    const { token } = authResponse.data;
    setSession(token);

    const getUserData = await getUserMe();
    let user = getUserData.data;

    if (!user.permissions) {
      user = { ...user, permissions: [] };
    }

    setActiveEnterprise('all');

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    console.log('New accounts must be created by users page');
    return;
  };

  const logout = () => {
    localStorage.removeItem('activeEnterprises');
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = (userData: User) => {
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        isLoggedIn: true,
        user: userData
      }
    });
  };

  const setActiveEnterprise = (enterprises: number | 'all') => {
    // Negative numbers erase data
    dispatch({
      type: SET_ACTIVE_ENTERPRISES,
      payload: {
        isLoggedIn: true,
        activeEnterprise: enterprises
      }
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile, setActiveEnterprise }}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
