import createApiInstance from 'api/apiInstance';

const loginService = async (login: string, password: string) => {
  try {
    const apiInstance = await createApiInstance();
    return await apiInstance.post('/session', { login, password });
  } catch (error) {
    console.error('Login error: ', error);
    throw error;
  }
};

export default loginService;
