import { FormControl, Select, MenuItem, Typography, OutlinedInput } from '@mui/material';
import { SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { Buildings2 } from 'iconsax-react';
import { ActiveEnterprise } from 'types/services';

type EnterpriseId = number | 'all';

const UserEnterpriseSelect = () => {
  const { user, activeEnterprise, setActiveEnterprise } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const inputWidth = isSmallScreen ? 60 : 220;

  const handleChange = (event: SelectChangeEvent<EnterpriseId>) => {
    const { value } = event.target;

    if (value === 'all') {
      setActiveEnterprise('all');
      return;
    }

    setActiveEnterprise(Number(value));
  };

  let allEnterprises: ActiveEnterprise[] = user?.enterprises || [];

  allEnterprises = allEnterprises.sort((a, b) => {
    if (a.name === null) return 1;
    if (b.name === null) return -1;
    return a.name.localeCompare(b.name);
  });

  allEnterprises = [
    {
      id: 'all',
      cnpj: null,
      name: 'Todas Empresas',
      active: null
    },
    ...allEnterprises
  ];

  const renderValue = (selected: EnterpriseId) => {
    if (isSmallScreen) {
      return <Buildings2 variant="Bold" />;
    }

    if (selected === 'all') {
      return <Typography variant="subtitle1">Todas Empresas</Typography>;
    }

    const selectedEnterprise = allEnterprises.find((enterprise) => enterprise.id === selected);
    return <Typography variant="subtitle1">{selectedEnterprise ? selectedEnterprise.name : 'Selecione uma empresa'}</Typography>;
  };

  return (
    <FormControl sx={{ minWidth: inputWidth }}>
      {activeEnterprise !== undefined ? (
        <Select
          id="active_enterprise"
          name="active_enterprise"
          placeholder="Empresas"
          autoComplete="off"
          value={activeEnterprise}
          displayEmpty
          onChange={handleChange}
          input={<OutlinedInput id="active-enterprise-outline-input" placeholder="select column" />}
          size="small"
          renderValue={() => renderValue(activeEnterprise)}
        >
          {allEnterprises.map((enterprise, index) => (
            <MenuItem key={index} value={enterprise.id}>
              <Typography variant="body1">{enterprise.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      ) : null}
    </FormControl>
  );
};

export default UserEnterpriseSelect;
