import { useState, MouseEvent } from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Logout, Profile, Setting2 } from 'iconsax-react';
import Customization from 'components/Customization';
import { useNavigate } from 'react-router';

// ==============================|| HEADER TAB ||============================== //

interface Props {
  handleLogout: () => void;
  closeDropdown: () => void;
}

const FullTab = ({ handleLogout, closeDropdown }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isCustomizationModalOpen, setIsCustomizationModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleListItemClick = (event: MouseEvent<HTMLDivElement>, index: number) => {
    setSelectedIndex(index);
  };

  const handleCustomizationOption = () => {
    setIsCustomizationModalOpen((prevData) => !prevData);
  };

  return (
    <>
      {isCustomizationModalOpen && <Customization isOpen={isCustomizationModalOpen} handleClose={handleCustomizationOption} />}
      <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event: MouseEvent<HTMLDivElement>) => {
            handleListItemClick(event, 0);
            closeDropdown();
            navigate('/profile/personal');
          }}
        >
          <ListItemIcon>
            <Profile variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Meu perfil" />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 1} onClick={handleCustomizationOption}>
          <ListItemIcon>{<Setting2 variant="Bulk" size={18} />}</ListItemIcon>
          <ListItemText primary={'Alterar tema'} />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
          <ListItemIcon>
            <Logout variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItemButton>
      </List>
    </>
  );
};

export default FullTab;
