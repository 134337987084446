import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon/coming-soon')));
const UserProfile = Loadable(lazy(() => import('pages/main-pages/profile/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/profiles/user/TabPersonal')));
const UserTabPassword = Loadable(lazy(() => import('sections/profiles/user/TabPassword')));
const UsersPage = Loadable(lazy(() => import('pages/main-pages/administrator/UsersPage')));
const ConfigurationPage = Loadable(lazy(() => import('pages/main-pages/administrator/ConfigurationPage')));
const EnterprisesPage = Loadable(lazy(() => import('pages/main-pages/administrator/EnterprisesPage')));
const SectorsPage = Loadable(lazy(() => import('pages/main-pages/administrator/SectorsPage')));
const ClientsPage = Loadable(lazy(() => import('pages/main-pages/registrations/ClientsPage')));
const ContactsPage = Loadable(lazy(() => import('pages/main-pages/registrations/ContactsPage')));
const ContractsPage = Loadable(lazy(() => import('pages/main-pages/registrations/ContractsPage')));
const DenyListClientsPage = Loadable(lazy(() => import('pages/main-pages/registrations/DenyListClientsPage')));
const LeadsPage = Loadable(lazy(() => import('pages/main-pages/registrations/LeadsPage')));
const RoutesPage = Loadable(lazy(() => import('pages/main-pages/registrations/RoutesPage')));
const TicketsPage = Loadable(lazy(() => import('pages/main-pages/registrations/TicketsPage')));
const BillingsPage = Loadable(lazy(() => import('pages/main-pages/financial/BillingsPage')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'profile',
          element: <UserProfile />,
          children: [
            {
              path: 'personal',
              element: <UserTabPersonal />
            },
            {
              path: 'password',
              element: <UserTabPassword />
            }
          ]
        },
        {
          path: 'administrator',
          children: [
            {
              path: 'configuration',
              element: <ConfigurationPage />
            },
            {
              path: 'enterprises',
              element: <EnterprisesPage />
            },
            {
              path: 'denylist',
              element: <DenyListClientsPage />
            },
            {
              path: 'sectors',
              element: <SectorsPage />
            }
          ]
        },
        {
          path: 'registers',
          children: [
            {
              path: 'users',
              element: <UsersPage />
            },
            {
              path: 'clients',
              element: <ClientsPage />
            },
            {
              path: 'contacts',
              element: <ContactsPage />
            },
            {
              path: 'contracts',
              element: <ContractsPage />
            },
            {
              path: 'leads',
              element: <LeadsPage />
            },
            {
              path: 'routes',
              element: <RoutesPage />
            },
            {
              path: 'tickets',
              element: <TicketsPage />
            }
          ]
        },
        {
          path: 'financial',
          children: [
            {
              path: 'billings',
              element: <BillingsPage />
            },
          ]
        },
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
