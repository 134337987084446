import { NavItemType } from 'types/menu';
import administrator from './administrator';
import registrations from './registrations';
import financial from './financial';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [administrator, registrations, financial]
};

export default menuItems;
