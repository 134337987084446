import { Helmet } from 'react-helmet';

interface Props {
  title?: React.ReactNode;
}

const HelmetNavigation = ({ title }: Props) => {
  return (
    <Helmet>
      <title>Fine Credit {title ? `| ${title}` : ''}</title>
    </Helmet>
  );
};

export default HelmetNavigation;
